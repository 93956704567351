import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useTranslation }  from 'hooks/useTranslation';
import { helpers } from '../../../../helpers/helpers';
import SortingButtons from './SortingButtons';


const onDragStart = (e, item, label) => {
  e.dataTransfer.setData("text/plain", item.id);
  let miniCard = document.createElement('div')
  miniCard.id = "mini-card-ghost"
  miniCard.style.top = "-100px"
  miniCard.style.left = "-100px"
  miniCard.innerHTML = label;
  document.getElementById('card-sorting').appendChild(miniCard);
  e.dataTransfer.setDragImage(miniCard,130,15)
}

const onDragEnd = (e)=>{
  document.getElementById('card-sorting').removeChild(document.getElementById('mini-card-ghost'))
}

const MainCard = ({save}) => {
  const assesmentTest = useSelector((state) => state.assesmentTest);
  const [item, setItem] = useState(null);
  const label = useTranslation("text", item?.question);
  const info  = useTranslation("info", item?.question);

  useEffect(() => {
    const questions = assesmentTest.assesment_test_questions;
    setItem(questions.find(item => item.answers.length === 0));

    return () => {
      setItem(null);
    }
  }, [assesmentTest.assesment_test_questions]);

  
  // Use this component to render html encoded accent
  const renderHTML = (rawHTML) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });

  if(!item) return(
    <div id="main-card" className="hero flat narrow">
      <div>
        <h2>(<FormattedMessage id="app.global.label.empty" />)</h2>
      </div>
    </div>
  );

  return(
    <>
    <div  id="main-card"
    className="hero"
    data-value={item.id}
    draggable={true}
    onDragStart={(e)=>{onDragStart(e, item, label)}}
    onDragEnd={onDragEnd}
    >
      <div>
        <h2>{ label}</h2>
        {
          info &&
          renderHTML(helpers.stripHtmlTags(info))
        }
      </div>
    </div>
    <SortingButtons
          item={item}
          save={save}
        />
    </>
  )
}

  export default MainCard